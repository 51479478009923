import { forOwn, isArray } from 'lodash';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private httpClient: HttpClient
  ) {
  }

  public get<T>(endpoint: string, params: any = {}, options: Object = {}): Observable<T> {
    params = this.prepareHttpParams(params);

    return this.httpClient.get<T>(`${environment.api_url}${endpoint}`, {
      params,
      ...options
    });
  }

  public post<T>(endpoint: string, data: any = {}, options: Object = {}): Observable<T> {
    return this.httpClient.post<T>(`${environment.api_url}${endpoint}`, data, options);
  }

  public put<T>(endpoint: string, data: any = {}, options: Object = {}): Observable<T> {
    return this.httpClient.put<T>(`${environment.api_url}${endpoint}`, data, options);
  }

  public patch<T>(endpoint: string, data: any = {}, options: Object = {}): Observable<T> {
    return this.httpClient.patch<T>(`${environment.api_url}${endpoint}`, data, options);
  }

  public delete<T>(endpoint: string, options: Object = {}): Observable<T> {
    return this.httpClient.delete<T>(`${environment.api_url}${endpoint}`, options);
  }

  private prepareHttpParams(params: any): HttpParams {
    let httpParams = new HttpParams();

    forOwn(params, (value, key) => {
      if (isArray(value)) {
        value.forEach((item) => {
          httpParams = httpParams.append(`${key}[]`, item);
        });
      } else {
        httpParams = httpParams.append(key, value);
      }
    });

    return httpParams;
  }
}
