import { AuthenticatedGuard, UnauthenticatedGuard } from './guards';
import { AuthService } from './services/auth.service';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    RouterModule
  ],
  providers: [
    AuthService,
    AuthenticatedGuard,
    UnauthenticatedGuard
  ]
})
export class AuthModule {
}
