import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
@Injectable({
  providedIn: 'root'
})
export class AuthUserService {

  constructor(
    private apiService:ApiService
  ) { }

  getAuthUser(){
    return this.apiService.get('/getDetails');
  }

  languageChange(language){
    return this.apiService.post('/settings/language',language);
  }

//   addUser(user:User){
//     return this.apiService.post('/users/registerUser',user);
//   }

//   editUser(userId:string,user:User){
//     return this.apiService.patch('/users/user/'+userId,user);
//   }
}
