import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }

  public setDetail(response){
    window.localStorage.setItem("response",window.btoa(unescape(encodeURIComponent(JSON.stringify(response)))))
  }

  public getDetail(){
    return decodeURIComponent(escape(atob(window.localStorage.getItem("response"))));
  }
  
}
