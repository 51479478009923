export const environment = {
  production: true,
  environment: 'preprod',
  web_app: 'https://app.preprod.fair-dogs.com',
  api_url: 'https://api.preprod.fair-dogs.com/api/v1/admin',
  googleMaps: {
    apiKey: 'AIzaSyDOdKEfMn_mzAlCFLSBGz2vVwkcy2cPULQ'
  },
  SENTRY_DSN: 'https://0d1b3e5107935321f7aacb247194af2f@o4506987790794752.ingest.us.sentry.io/4507001789022208',
};
