import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable,throwError  } from 'rxjs';
import { AuthService } from 'app/shared/auth/services/auth.service';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ToastrNotificationService } from 'app/shared/services/toastr-notification.service';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {

  constructor(private authService:AuthService,
    private toastrService:ToastrNotificationService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.authService.getToken()}`
      }
    });
    return next.handle(request)
    .pipe(
      catchError((error: HttpErrorResponse) => {
       if(error.status===401){
         if(error.error){
          this.toastrService.showError("",error.error.message)
          this.authService.logout();
         }
       }
        return throwError(error);
      })
    )
  }
}
