import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastrNotificationService {

  constructor(
    private toastr: ToastrService
  ) { }

  showSuccess(message: string, title: string) {
    this.toastr.success(message, title)
  }

  showError(message: string, title: string) {
    this.toastr.error(message, title)
  }

  showHttpError(error: HttpErrorResponse) {
    if (error.error) {
      if (!error.error.success) {
        this.showError('', error.error.message);
      }
    } else {
      this.showError('Something went wrong', 'Error');
    }
  }
}
