import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import firebase from 'firebase/app'
import { Observable } from 'rxjs';
import { ApiService } from 'app/shared/api/api.service';
import { catchError, map, share, tap } from 'rxjs/operators';
import { UtilService} from 'app/shared/services/util-service.service';
import { ForgotPassword, RegisterAdmin, ResetPassword,LoginAdmin } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private user: Observable<firebase.User>;
  private userDetails: firebase.User = null;

  constructor(
    public _firebaseAuth: AngularFireAuth, 
    public router: Router,
    private apiService:ApiService,
    private utilService:UtilService
    ) {
    this.user = _firebaseAuth.authState;
    this.user.subscribe(
      (user) => {
        if (user) {
          this.userDetails = user;
        }
        else {
          this.userDetails = null;
        }
      }
    );

  }


  signinAdmin(credentials:LoginAdmin) {
    //your code for checking credentials and getting tokens for for signing in user
    // return this._firebaseAuth.signInWithEmailAndPassword(email, password)

    //uncomment above firebase auth code and remove this temp code
    
    return this.apiService
      .post<any>('/login', credentials)
      .pipe(
        tap((response) => {
          this.storeAuthResponse(response);
          return response;
        })
      );

    // return new Promise(function(resolve, reject) {
    //   setTimeout(function() {
    //     window.localStorage.setItem('token',"abc")
    //     resolve(true);
    //   }, 1000);
    // });

  }

  signupAdmin(registerAdmin:RegisterAdmin){
    return this.apiService
    .post<any>('/register', registerAdmin)
    .pipe(
      tap((response) => {
        this.storeAuthResponse(response);
        return response;
      })
    );
  }

  forgotPassword(forgotpasswordForm:ForgotPassword){
    return this.apiService.post('/forgotPassword',forgotpasswordForm)
  }

  resetPassword(resetpasswordForm:ResetPassword,token:string){
    return this.apiService.post('/resetPassword?token='+token,resetpasswordForm)
  }

  logout() {
    this._firebaseAuth.signOut();
    this.removeToken();
    this.removeAuthResponse();
    this.router.navigate(['/login']);
  }

  isAuthenticated() {
    return window.localStorage.getItem('token');
  }

  private storeAuthResponse(response){
      this.utilService.setDetail(response)
      this.saveToken(response.data.token)
  }

  public getToken(): string {
    return window.localStorage.getItem('token');
  }

  private saveToken(token: string): void {
    window.localStorage.setItem('token', token);
  }

  private removeToken(): void {
    window.localStorage.removeItem('token');
  }

  private removeAuthResponse():void{
    window.localStorage.removeItem('response')
  }

  public getDetail(){
    return this.utilService.getDetail()
  }
}
