import { RouteInfo } from './vertical-menu.metadata';

// Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: '', title: 'DASHBOARD', icon: 'ft-home', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
    submenu: [
      {
        path: '/dashboard/statistics', title: 'STATISTICS', icon: 'ft-arrow-right submenu-icon',
        class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      // { path: '/list', title: 'LIST', icon: 'ft-arrow-right submenu-icon',
      // class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'USERS', icon: 'fa fa-user', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
    submenu: [
      {
        path: '/users/add', title: 'ADD_USER', icon: 'ft-arrow-right submenu-icon',
        class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/users/list', title: 'LIST', icon: 'ft-arrow-right submenu-icon',
        class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
    ]
  },
  {
    path: '', title: 'CATEGORY', icon: 'ft-align-left', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
    submenu: [
      {
        path: '/category/add', title: 'ADD_CATEGORY', icon: 'ft-arrow-right submenu-icon',
        class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/category/list', title: 'LIST', icon: 'ft-arrow-right submenu-icon',
        class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
    ]
  },
  {
    path: '', title: 'ROLE', icon: 'ft-shield', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
    submenu: [
      {
        path: '/role/add', title: 'ADD_ROLE', icon: 'ft-arrow-right submenu-icon',
        class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/role/list', title: 'LIST', icon: 'ft-arrow-right submenu-icon',
        class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
    ]
  },
  {
    path: '', title: 'DOG', icon: 'ft-gitlab', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
    submenu: [
      {
        path: '/dog/add', title: 'ADD_DOG', icon: 'ft-arrow-right submenu-icon',
        class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/dog/list', title: 'LIST', icon: 'ft-arrow-right submenu-icon',
        class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
    ]
  },
  {
    path: '', title: 'DISCOUNT', icon: 'ft-gitlab', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
    submenu: [
      {
        path: '/discount/add', title: 'DISCOUNT_ADD', icon: 'ft-arrow-right submenu-icon',
        class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/discount/list', title: 'DISCOUNT_LIST', icon: 'ft-arrow-right submenu-icon',
        class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
    ]
  },
  {
    path: '', title: 'ADVERTISEMENT', icon: 'ft-gitlab', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
    submenu: [
      {
        path: '/advertisement/add', title: 'ADVERTISEMENT_ADD', icon: 'ft-arrow-right submenu-icon',
        class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/advertisement/list', title: 'ADVERTISEMENT_LIST', icon: 'ft-arrow-right submenu-icon',
        class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
    ]
  },
  {
    path: '', title: 'META', icon: 'ft-gitlab', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
    submenu: [
      // {
      //   path: '/meta/edit/:id', title: 'META_ADD', icon: 'ft-arrow-right submenu-icon',
      //   class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      // },
      {
        path: '/meta/list', title: 'META_LIST', icon: 'ft-arrow-right submenu-icon',
        class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
    ]
  }
];
