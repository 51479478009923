import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'users',
    loadChildren: () => import('../../components/users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'category',
    loadChildren: () => import('../../components/categories/categories.module').then(m => m.CategoriesModule)
  },
  {
    path: 'role',
    loadChildren: () => import('../../components/roles/roles.module').then(m => m.RolesModule)
  },
  {
    path: 'dog',
    loadChildren: () => import('../../components/dogs/dogs.module').then(m => m.DogsModule)
  },
  {
    path: 'discount',
    loadChildren: () => import('../../components/discount/discount.module').then(m => m.DiscountModule)
  },
  {
    path: 'advertisement',
    loadChildren: () => import('../../components/advertisement/advertisement.module').then(m => m.AdvertisementModule)
  },
  {
    path: 'meta',
    loadChildren: () => import('../../components/meta/meta.module').then(m => m.MetaModule)
  },
  {
    path: 'issue',
    loadChildren: () => import('../../components/issues/issues.module').then(m => m.IssuesModule)
  }

];
